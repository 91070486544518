@font-face {
  font-family: iranyekanfa;
  font-style: normal;
  font-weight: bold;
  src: url("https://rebix.ir/_ragc/styles/fonts/eot/iranyekanwebboldfanum.eot");
  src: url("https://rebix.ir/_ragc/styles/fonts/eot/iranyekanwebboldfanum.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-8 */
      url("https://rebix.ir/_ragc/styles/fonts/woff/iranyekanwebboldfanum.woff")
      format("woff"),
    /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
      url("https://rebix.ir/_ragc/styles/fonts/ttf/iranyekanwebboldfanum.ttf")
      format("truetype");
}

@font-face {
  font-family: iranyekanfa;
  font-style: normal;
  font-weight: 100;
  src: url("https://rebix.ir/_ragc/styles/fonts/eot/iranyekanwebthinfanum.eot");
  src: url("https://rebix.ir/_ragc/styles/fonts/eot/iranyekanwebthinfanum.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-8 */
      url("https://rebix.ir/_ragc/styles/fonts/woff/iranyekanwebthinfanum.woff")
      format("woff"),
    /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
      url("https://rebix.ir/_ragc/styles/fonts/ttf/iranyekanwebthinfanum.ttf")
      format("truetype");
}

@font-face {
  font-family: iranyekanfa;
  font-style: normal;
  font-weight: 300;
  src: url("https://rebix.ir/_ragc/styles/fonts/eot/iranyekanweblightfanum.eot");
  src: url("https://rebix.ir/_ragc/styles/fonts/eot/iranyekanweblightfanum.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-8 */
      url("https://rebix.ir/_ragc/styles/fonts/woff/iranyekanweblightfanum.woff")
      format("woff"),
    /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
      url("https://rebix.ir/_ragc/styles/fonts/ttf/iranyekanweblightfanum.ttf")
      format("truetype");
}

@font-face {
  font-family: iranyekanfa;
  font-style: normal;
  font-weight: normal;
  src: url("https://rebix.ir/_ragc/styles/fonts/eot/iranyekanwebregularfanum.eot");
  src: url("https://rebix.ir/_ragc/styles/fonts/eot/iranyekanwebregularfanum.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-8 */
      url("https://rebix.ir/_ragc/styles/fonts/woff/iranyekanwebregularfanum.woff")
      format("woff"),
    /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
      url("https://rebix.ir/_ragc/styles/fonts/ttf/iranyekanwebregularfanum.ttf")
      format("truetype");
}

@font-face {
  font-family: iranyekanfa;
  font-style: normal;
  font-weight: 500;
  src: url("https://rebix.ir/_ragc/styles/fonts/eot/iranyekanwebmediumfanum.eot");
  src: url("https://rebix.ir/_ragc/styles/fonts/eot/iranyekanwebmediumfanum.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-8 */
      url("https://rebix.ir/_ragc/styles/fonts/woff/iranyekanwebmediumfanum.woff")
      format("woff"),
    /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
      url("https://rebix.ir/_ragc/styles/fonts/ttf/iranyekanwebmediumfanum.ttf")
      format("truetype");
}

@font-face {
  font-family: iranyekanfa;
  font-style: normal;
  font-weight: 800;
  src: url("https://rebix.ir/_ragc/styles/fonts/eot/iranyekanwebextraboldfanum.eot");
  src: url("https://rebix.ir/_ragc/styles/fonts/eot/iranyekanwebextraboldfanum.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-8 */
      url("https://rebix.ir/_ragc/styles/fonts/woff/iranyekanwebextraboldfanum.woff")
      format("woff"),
    /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
      url("https://rebix.ir/_ragc/styles/fonts/ttf/iranyekanwebextraboldfanum.ttf")
      format("truetype");
}

@font-face {
  font-family: iranyekanfa;
  font-style: normal;
  font-weight: 850;
  src: url("https://rebix.ir/_ragc/styles/fonts/eot/iranyekanwebblackfanum.eot");
  src: url("https://rebix.ir/_ragc/styles/fonts/eot/iranyekanwebblackfanum.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-8 */
      url("https://rebix.ir/_ragc/styles/fonts/woff/iranyekanwebblackfanum.woff")
      format("woff"),
    /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
      url("https://rebix.ir/_ragc/styles/fonts/ttf/iranyekanwebblackfanum.ttf")
      format("truetype");
}

@font-face {
  font-family: iranyekanfa;
  font-style: normal;
  font-weight: 900;
  src: url("https://rebix.ir/_ragc/styles/fonts/eot/iranyekanwebextrablackfanum.eot");
  src: url("https://rebix.ir/_ragc/styles/fonts/eot/iranyekanwebextrablackfanum.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-8 */
      url("https://rebix.ir/_ragc/styles/fonts/woff/iranyekanwebextrablackfanum.woff")
      format("woff"),
    /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
      url("https://rebix.ir/_ragc/styles/fonts/ttf/iranyekanwebextrablackfanum.ttf")
      format("truetype");
}

* {
  outline: none;
}

body {
  margin: 0 !important;
  font-family: "iranyekanfa";
  direction: rtl;
  text-align: right;
  min-width: auto;
  box-sizing: border-box !important;
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  overflow-x: hidden;
}

hr {
  border: 0px solid #eee;
  border-bottom: 2px solid #eee;
}

img {
  max-width: 100%;
  height: auto;
}

.header {
  background: #3d5afe;
  color: #fff;
  padding: 30px 0;
}

.headerAfter {
  background: linear-gradient(200deg, #3d5afe 49%, #fff 49%);
  height: 25px;
}

.footer {
  background: #f2f2f2;
  color: #333;
  padding: 15px 0;
  font-size: 14px;
}

.mainBlockBox {
  padding: 70px 0;
  /* background: linear-gradient(#fff, #f5f5f5); */
  background: #fff;
}
.mainBlockBox:nth-of-type(even) {
  /* background: linear-gradient(#f5f5f5, #fff); */
  background: #fff;
}

.mainPartners {
  background: #3d5afe;
  padding: 30px;
  margin: 15px 0 60px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  overflow-x: auto;
}

.mainPartners img {
  margin: 7px 30px;
  max-height: 60px;
}

.containerBlock {
  border: 1px solid #ddd;
  border-radius: 7px;
  padding: 21px;
}

.containerBlock .downloadXSize {
  min-height: 40px;
  display: block;
}

.containerBlock.serverlist {
  height: 100%;
}

.containerBlock.serverlist .mysx {
  height: 330px;
}

.containerBlock.serverlist img {
  height: 22px;
}

.containerBlock.serverlist .proto {
  background: #6bff3152;
  border-radius: 4px;
  border: 1px solid #6bff31;
  padding: 4px;
  font-size: 12px;
  font-weight: 800;
}

.p-downloadIcons {
  margin: 20px 0;
}
.p-downloadIcons svg {
  margin: 0 auto 10px;
  font-size: 38px;
  color: #2e46cf;
  display: block;
  text-align: center;
}

.cursorppp * {
  cursor: pointer;
}

.rg_countrySelection .MuiFormControlLabel-label {
  width: 100%;
}
.rg_countrySelection .MuiFormControlLabel-root {
  background: #f2f2f2;
  border-radius: 10px;
  margin: 0;
  margin-bottom: 10px;
}
.rg_countrySelection .MuiFormControlLabel-root:last-of-type {
  margin-bottom: 0px;
}

@media (max-width: 599px) {
  .containerBlock.serverlist {
    height: auto;
  }

  .containerBlock.serverlist .mysx {
    height: auto;
  }
}
